import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

class SendMailError extends React.Component {
  handleSubmit() {}

  render() {
    var formStyle = {
      paddingTop: 6 + "em",
      paddingBottom: 6 + "em",
    };
    let frontendUrl = (
      <a href="./#/pwd/form">
        <FormattedMessage id="Here" />
      </a>
    );

    return (
      <div className="vertical-center no-bg p-0 m-0 text-center">
        <div className="container pt-5 pl-5 pr-5 pb-0 mw-600">
          <img
            className="logo-login"
            alt=""
            src="./images/svg/logo_peps-digital.svg"
          />

          <div style={formStyle}>
            <span className="fa-stack fa-lg text-warning fa-5x mb-3">
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-warning fa-stack-1x fa-inverse"></i>
            </span>
            <p className="alert alert-warning">
              <FormattedMessage
                id="Send.Mail.Error"
                values={{ url: frontendUrl }}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMailError);
