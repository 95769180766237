import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { getUser } from "../../actions/user/user";
import APIUrl from "../../APIUrl";
import { logout } from "../../actions/authentication/authentication";
import Util from "../../util/Util";

class Eforbizz extends React.Component {
  constructor(props) {
    super(props);

    this.props.onGetUser();
  }

  // Logout from app
  logout(e, url) {
    e.preventDefault();
    e.stopPropagation();

    let that = this;

    function onLogoutSuccess() {
      // Redirect to another e4BIZZ app after logout
      if (url && url.indexOf("http") !== -1) {
        window.location.replace(url);
      }
      // No redirection: pure logout
      else {
        that.props.history.push("/");
      }
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  render() {
    let appCount = [];
    if (this.props.user.access_e4coll)
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4COLL_URL));

    if (this.props.user.access_e4mad)
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4MAD_URL));

    if (this.props.user.access_e4vhp)
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4VHP_URL));

    // User have access to a single app: we automatically redirect to this app
    if (appCount.length === 1) {
      window.location.replace(appCount + APIUrl.jwtToken);
      return false;
    }

    let e4collUrl = "/eforbizz";
    let e4vhpUrl = "/eforbizz";
    let e4madUrl = "/eforbizz";

    // Check that user has the access rights
    if (this.props.user.access_e4coll)
      e4collUrl = Util.checkHttps(process.env.REACT_APP_E4COLL_URL);
    if (this.props.user.access_e4vhp)
      e4vhpUrl = Util.checkHttps(process.env.REACT_APP_E4VHP_URL);

    if (this.props.user.access_e4mad)
      e4madUrl = Util.checkHttps(process.env.REACT_APP_E4MAD_URL);

    if (e4collUrl.indexOf("http") !== -1) e4collUrl += APIUrl.jwtToken;
    if (e4vhpUrl.indexOf("http") !== -1) e4vhpUrl += APIUrl.jwtToken;
    if (e4madUrl.indexOf("http") !== -1) e4madUrl += APIUrl.jwtToken;

    // Apps logos
    let e4collLogo = "./images/svg/logo_pepsicoll.png";
    let e4vhpLogo = "./images/svg/logo_pepsihandicap.png";
    let e4madLogo = "./images/svg/logo_pepsimad.png";

    return (
      <React.Fragment>
        <div className="divEforbizz">
          <div className="container text-center pt-5 pl-5 pr-5 pb-0 mw-600">
            <img
              alt=""
              className="w-75 img-fluid"
              src="./images/svg/logo_peps-digital.svg"
            />
          </div>

          <div className="m-5 text-center">
            <div className="row">
              {this.props.user.access_e4coll && (
                <div className="card">
                  <h5 className="card-header d-flex align-items-center">
                    <img alt="appLogo" src={e4collLogo} className="mx-auto" />
                  </h5>
                  <div className="card-body">
                    <button
                      onClick={(e) => this.logout(e, e4collUrl)}
                      className="btn btn-info"
                    >
                      <FormattedMessage id="Open.App" />
                    </button>
                  </div>
                </div>
              )}

              {this.props.user.access_e4mad && (
                <div className="card">
                  <h5 className="card-header d-flex align-items-center">
                    <img alt="appLogo" src={e4madLogo} className="mx-auto" />
                  </h5>
                  <div className="card-body">
                    <button
                      onClick={(e) => this.logout(e, e4madUrl)}
                      className="btn btn-info"
                    >
                      <FormattedMessage id="Open.App" />
                    </button>
                  </div>
                </div>
              )}

              {this.props.user.access_e4vhp && (
                <div className="card">
                  <h5 className="card-header d-flex align-items-center">
                    <img alt="appLogo" src={e4vhpLogo} className="mx-auto" />
                  </h5>
                  <div className="card-body">
                    <button
                      onClick={(e) => this.logout(e, e4vhpUrl)}
                      className="btn btn-info"
                    >
                      <FormattedMessage id="Open.App" />
                    </button>
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={(e) => this.logout(e)}
              className="btn btn-secondary mt-5"
            >
              <FormattedMessage id="Disconnect" />
            </button>

            <div className="mt-5 text-center support-info">
              <div className="support-container">
                <div className="support-item">
                  <span className="support-label">Mail :</span>
                  <a href="mailto:technique@peps-digital.fr">
                    technique@peps-digital.fr
                  </a>
                </div>
                <div className="support-item">
                  <span className="support-label">Tel :</span>
                  <span className="support-value">07 56 83 69 66</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Eforbizz));
